import {ApiService} from '@/services/ApiService'
import http from '@/http-common'

const URL = '/api/freightlist'

class FreightlistService extends ApiService {
    constructor(){
        super()
    }
    getUrl(){
        return URL
    }
    updateMeasurements(id, value, type){
        return http.post(`${URL}/measurements/${type}`, {'id': id, 'value': value})
    }
    bulkEdit(voyageId, data){
        return http.post(`${URL}/bulk-edit-freight/${voyageId}`, data)
    }
    updateCheckedStatus(ids){
        return http.post(`${URL}/checked-status`, {'ids': ids})
    }
}

export default new FreightlistService()