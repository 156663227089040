<template>
    <div>
        <Overview 
            :moduleName="this.formatModuleName(this.$route.name, this.$i18n, false)"
            :moduleNamePlural="this.formatModuleName(this.$route.name, this.$i18n)"
            :sort="'created_at'"
            :itemNameField="'number'"
            :itemService=itemService
            :itemFormRef=itemFormRef
            :sortOrder="2"
            modalWidth="1400px"
            :selectFields=selectFields
            :showAddButton="false"
            :showDropdown="false"
            :hideFooter="true"
        >   
        <template #columns>
            <Column field="vessel.name" :header="$tc('navigation.vessels', 1)" style="width: 25%"></Column>
            <Column field="number" :header="$t('navigation.voyages')" style="width: 120px"></Column>
            <Column field="carrier.relation.name" :header="$tc('navigation.carriers', 1)" style="width: 20%"></Column>
            <Column field="voyage_status_id" :header="`Booking status`" style="width: 120px">
                <template #body="slotProps"> 
                    <span class="badge" :data-status="$t('voyage_status.'+slotProps.data.voyage_status_id).toLowerCase()">
                        {{slotProps.data.voyage_status_id ? $t('voyage_status.'+slotProps.data.voyage_status_id) : ''}}
                    </span>
                </template>
            </Column>
            <Column field="created_at" :header="`Created`" style="width: 120px">
                <template #body="slotProps"> {{formatDate(slotProps.data.created_at, 'shortDate')}} </template>
            </Column>
        </template>

        <template #form>
            <FreightListForm ref="ItemForm" :itemData="item" />
        </template>
        </Overview>
    </div>
</template>

<script>
import Overview from '@/components/Overview.vue';

import voyageService from '@/services/FreightlistService';
import Column from '@/components/column';
import FreightListForm from '@/components/freightlist/FreightListForm.vue';

export default {
    components: {
        Overview,
        Column,
        FreightListForm
    },
    data() {
        return {
            item: {},
            itemService: voyageService,
            itemFormRef: null,
            selectFields: [
                "id",
                "number",
                "updated_at",
                "created_at",
                "region_type_id",
                "vessel_id",
                "carrier_id",
                "voyage_status_id",
                {
                    "vessel": [
                        "id",
                        "code",
                        "name"
                    ]
                },
                {
                    "carrier": [
                        "id",
                        "relation_id",
                        {
                            "relation": [
                                "id",
                                "name"
                            ]
                        }
                    ]
                }
            ]
        }
    },
    mounted() {
        this.itemFormRef = this.$refs.ItemForm;
    }
}
</script>